class Value {
  constructor (categoryId, value = null) {
    if (categoryId === undefined || categoryId === '' || categoryId === null) {
      throw new Error('Необходимо указать id категории')
    }
    this.id = null
    this.category_id = categoryId
    this.value = value || 'Новое значение'
    this.complexValue = []
  }
}

export default Value
