export const listOfProjection = [
  {
    projection: 'EPSG:4326 (Стандартная проекция)', value: '+proj=longlat +datum=WGS84 +no_defs +type=crs'
  },
  {
    projection: 'WGS 84 / UTM zone 37N', value: '+proj=utm +zone=37 +datum=WGS84 +units=m +no_defs'
  },
  {
    projection: 'Краснодарский край МСК-23 зона 1',
    value: '+proj=tmerc +lat_0=0 +lon_0=37.98333333333 +k=1 +x_0=1300000 +y_0=-4511057.628 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,0.35,0.79,-0.22 +units=m +no_defs'
  },
  {
    projection: 'Краснодарский край МСК-23 зона 2',
    value: '+proj=tmerc +lat_0=0 +lon_0=40.98333333333 +k=1 +x_0=2300000 +y_0=-4511057.628 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,0.35,0.79,-0.22 +units=m +no_defs'
  },
  {
    projection: 'Ростовская область МСК-61 зона 1',
    value: '+proj=tmerc +lat_0=0 +lon_0=37.98333333333 +k=1 +x_0=1300000 +y_0=-4811057.628 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,0.35,0.79,-0.22 +units=m +no_defs'
  },
  {
    projection: 'Ростовская область МСК-61 зона 2',
    value: '+proj=tmerc +lat_0=0 +lon_0=40.98333333333 +k=1 +x_0=2300000 +y_0=-4811057.628 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,0.35,0.79,-0.22 +units=m +no_defs'
  },
  {
    projection: 'Ростовская область МСК-61 зона 3',
    value: '+proj=tmerc +lat_0=0 +lon_0=43.98333333333 +k=1 +x_0=3300000 +y_0=-4811057.628 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,0.35,0.79,-0.22 +units=m +no_defs'
  }
]