class ComplexValue {
  constructor (categoryId) {
    this.id = null
    this.group_id = null
    this.category_id = categoryId
    this.value_id = null
  }
}

export default ComplexValue
