<template>
  <BaseCard :scroll-top="scrollTop">
    <template #header>
      <v-card-text class="d-flex justify-start align-center">
        <div class="subtitle-1 mr-2">{{ category.name }}</div>
        <v-btn
          x-small
          color="primary"
          @click="handleAdd"
        >
          {{ $t('handbook.addValue') }}
          <v-icon x-small>mdi-plus</v-icon>
        </v-btn>
      </v-card-text>
    </template>
    <template #content>
      <v-data-table
        :items="category.values"
        item-key="id"
        :options.sync="tableOptions"
        dense
        class="handbook-tables mb-3 scroll-header-tables"
        :footer-props="{
            'items-per-page-options': [25, 50, 100]
          }"
      >
        <template v-slot:header>
          <thead>
          <tr>
            <th
              v-for="header in category.categoryGroups"
              :key="header.id"
              class="text-left"
            >
              {{ header.category.name }}
            </th>
            <th>
              {{ $t('base.actions') }}
            </th>
          </tr>
          </thead>
        </template>
        <template v-slot:item="{item, index}">
          <tr>
            <td
              v-for="(col, colIndex) in item.complexValue"
              :key="colIndex"
              class="pa-2"
            >
              <SearchSelectHandbook
                item-value="id"
                item-text="value"
                :items="getCategory(col.category_id)"
                :category-id="col.category_id"
                :value="col.value_id"
                v-model="col.value_id"
              />
            </td>
            <td>
              <v-btn
                x-small
                class="ml-2"
                :title="$t('base.delete')"
                icon
                @click.stop="handleDelete(index)"
              >
                <v-icon x-small>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </BaseCard>
</template>

<script>
import Value from '@/components/structures/handbook/value'
import ComplexValue from '@/components/structures/handbook/complexValue'
import { cleanClone } from '@/components/utils/common'
import BaseCard from '@/components/base/BaseCard'
import scrollTop from '@/components/mixins/scrollTop'
import detailValue from '@/components/mixins/detailValue'
import SearchSelectHandbook from '@/components/views/account/handbook/detail/detailValue/SearchSelectHandbook'
import _ from 'lodash'

export default {
  mixins: [scrollTop, detailValue],
  name: 'ComplexValue',
  components: { SearchSelectHandbook, BaseCard },
  props: {
    value: Object
  },
  watch: {
    category: {
      handler () {
        this.$emit('input', cleanClone(this.category))
      },
      deep: true
    }
  },
  methods: {
    //todo: правим косяк с пустыми значениям, после исправления нужно удалить
    checkValues () {
      let headerCols = this.category.categoryGroups
      this.category.values.forEach(value => {
        if (value.complexValue.length < headerCols.length) {
          headerCols.forEach((col, colIndex) => {
            if (value.complexValue.length <= colIndex || col.category.id !== value.complexValue[colIndex].category_id) {
              value.complexValue.splice(colIndex, 0, new ComplexValue(col.category.id))
            }
          })
        }
      })
    },
    handleAdd () {
      let newValue = new Value(this.value.id)
      this.category.categoryGroups.forEach(group => {
        newValue.complexValue.push(new ComplexValue(group.category.id))
      })
      const copy = _.cloneDeep(this.category.values)
      copy.unshift(newValue)
      this.$set(this.category, 'values', copy)
      this.scrollTop = !this.scrollTop
    },
    getCategory (id) {
      return this.$store.getters['handbook/getListItemById'](id).values
    }
  }
}
</script>

<style scoped>
  /deep/ .scroll-header-tables .v-data-table__wrapper {
    max-height: calc(100vh - 178px) !important;
  }
</style>
