<template>
  <div>
    <BaseCard
      v-if="category"
      :scroll-top="scrollTop"
    >
      <template #header>
        <v-card-text class="d-flex justify-start align-center">
          <div class="subtitle-1 mr-2">{{ category.name }}</div>
          <BaseBtn
            :title="$t('handbook.addValue')"
            @click="addValue"
            icon="mdi-plus"
            x-small
            color="primary"
            is-small-icon
          />
          <BaseBtn
            :title="$t('base.addList')"
            @click="dialog = !dialog"
            icon="mdi-playlist-plus"
            x-small
            color="primary"
            is-small-icon
          />

          <BaseDialog
            :value="dialog"
            :max-width="300"
            v-if="category"
            :label="$t('message.addList')"
            is-cancel-btn
            @input="dialog = false"
            :text-cancel="$t('base.cancel')"
            :text-confirm="$t('handbook.addValueList')"
            :is-confirm-btn="!!userValuesList"
            @confirm="addValueList"
          >
            <template #content>
              <v-card-text
                style="height: 250px; overflow: hidden"
              >
                <v-textarea
                  height="200"
                  class="mt-4"
                  no-resize
                  v-model="userValuesList"
                  dense
                  @keydown.enter.down="handleEnterTextarea"
                  :placeholder="$t('message.enterList')"
                  outlined
                ></v-textarea>
              </v-card-text>
            </template>
          </BaseDialog>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            class="ma-0 pt-0"
            append-icon="mdi-magnify"
            label="Поиск"
            clearable
            clear-icon="mdi-close"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
      </template>
      <template #content>
        <v-data-table
          dense
          :items="category.values"
          :options.sync="tableOptions"
          :search="search"
          :headers="headers"
          item-key="id"
          :footer-props="{
              'items-per-page-options': [25, 50, 100]
            }"
          class="mb-3 scroll-header-tables"
        >
          <template v-slot:item="{item, index}">
            <tr>
              <td v-if="category.values">
                <v-list-item
                  class="pl-0 pr-0 mt-5"
                  :key="item.id + '-' + index + '-' + category.values.length"
                >
                  <v-text-field
                    v-model.trim="item.value"
                    dense
                    :background-color="!item.id ? 'rgba(230,243,253,0.8)' : null"
                    outlined
                    @change="handleValidate(index)"
                    :error-messages="checkErrors('category.values.$each.' + index+ '.value')"
                    :label="serialNumber(index)"
                  ></v-text-field>
                </v-list-item>
              </td>
              <td>
                <v-btn
                  icon
                  small
                  @click="handleDelete(index)"
                  class="ml-2"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </BaseCard>
  </div>
</template>

<script>
import { cleanClone } from '@/components/utils/common'
import Value from '@/components/structures/handbook/value'
import BaseAlert from '@/components/base/UI/BaseAlert'
import BaseCard from '@/components/base/BaseCard'
import BasePreloader from '@/components/base/UI/BasePreloader'
import scrollTop from '@/components/mixins/scrollTop'
import detailValue from '@/components/mixins/detailValue'
import validationErrors from '@/components/mixins/validationErrors'
import BaseDialog from '@/components/base/BaseDialog'
import BaseBtn from '@/components/base/UI/BaseBtn'

export default {
  mixins: [scrollTop, detailValue, validationErrors],
  name: 'SimpleValue',
  components: { BaseBtn, BaseDialog, BasePreloader, BaseCard, BaseAlert },
  props: {
    value: Object
  },
  data () {
    return {
      dialog: false,
      userValuesList: null,
      // search и headers нужны для работы поиска
      search: '',
      headers: [
        { text: 'Значения', value: 'value', sortable: false },
        { text: 'Действия', sortable: false }
      ]
    }
  },
  inject: ['$v'],
  watch: {
    'category.values': {
      handler () {
        this.$emit('input', cleanClone(this.category))
      },
      deep: true
    }
  },
  methods: {
    handleEnterTextarea (event) {
      if (!event.shiftKey) {
        event.preventDefault()
      }
    },
    addValue () {
      this.category.values.unshift(new Value(this.category.id))
      this.scrollTop = !this.scrollTop
    },
    addValueList () {
      //note: valueListToArr список новых значений получаем в отдельную переменную, чтобы не валидировать старые значения
      let valueListToArr = this.userValuesList.split('\n').map(value => new Value(this.category.id, value))
      this.category.values = valueListToArr.concat(this.category.values)
      this.userValuesList = null
      this.dialog = false
      return this.$nextTick(() => {
        valueListToArr.forEach((item, index) => this.handleValidate(index))
      })
    },
    serialNumber (index) {
      let serialNumber = 'Значение ' + (this.indexOffset + index + 1)
      if (this.category.values[index].id === null) {
        serialNumber = 'Новое значение'
      }
      return serialNumber
    },
    handleValidate (index) {
      this.$v.category.values.$each[index].value.$touch()
    }
  }
}
</script>

<style scoped>
  /deep/ .v-list:hover,
  /deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover .v-list {
    background: #eeeeee;
  }
  /deep/ .scroll-header-tables .v-data-table__wrapper {
    max-height: calc(100vh - 178px);
  }
</style>
