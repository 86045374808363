<template>
  <v-autocomplete
    :items="items"
    :value="value"
    @change="changeValue"
    :item-value="itemValue"
    :item-text="itemText"
    :disabled="disabled"
    :search-input.sync="search"
    dense
    outlined
    flat
    :no-filter="isNoFilter"
    :multiple="false"
    :menu-props="{ maxHeight: '200px' }"
    hide-details
    :class="classText"
    :placeholder="'Поиск '"
  >
  </v-autocomplete>
</template>

<script>
import builders from '@/components/views/account/passport/detail/formBuilder/builders'

export default {
  name: 'SearchSelectHandbook',
  mixins: [builders],
  props: {
    value: [Array, Number, String, Object],
    items: Array,
    itemText: {
      type: String,
      default: 'value'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    label: String,
    name: String,
    categoryId: Number,
    disabled: Boolean,
    minNumberOfWords: { type: Number, default: 1 },
    classForm: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dataItems: [],
      loading: false,
      search: null,
      model: [],
      autocompleteString: ''
    }
  },
  watch: {
    search: {
      async handler () {
        const clearSearch = this.search
        if (clearSearch === '' || clearSearch === null) {
          this.autocompleteString = ''
        } else if (!this.loading && clearSearch.length >= this.charsToSearch && (!this.model || this.search !== this.model[this.itemText])) {
          this.autocompleteString = clearSearch
        } else {
          this.autocompleteString = ''
        }
        return true
      }
    },
    model: {
      handler: function () {
        if (this.model) {
          let data
          if (Array.isArray(this.model)) {
            data = this.model?.map((item) => {
              if (item?.id) {
                return item.id
              } else if (item) {
                return item // item.id обычно нет, если item - просто число, а не объект
              }
            })
          } else {
            data = this.model[this.itemValue] || this.model // либо объект, либо просто число
          }
          this.$emit('input', data)
        }
      },
      deep: true
    },
    items: {
      handler () {
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    fillModelChips () { //заполнение model
      this.model = this.items.find(item => item.id === this.value)
    },
    changeValue (event) {
      this.$emit('input', event)
    }
  },
  computed: {
    isNoFilter () {
      // не фильтровать если количество слов меньше minNumberOfWords
      return this.search?.split(' ').length < this.minNumberOfWords
    }
  },
  mounted () {
    this.fillModelChips()
  }
}
</script>

<style scoped>
  .noTransformAppendIcon /deep/ .v-input__control .v-select__slot .v-input__append-inner .v-icon {
    transform: none !important;
  }
  /deep/ .v-text-field input {
    padding: 3px 0 2px;
  }
</style>
