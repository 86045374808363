<template>
  <div>
    <SimpleValue
      v-if="categoryType === typeByLabel('Простой список')"
      v-model="category"
    />
    <ComplexValue
      v-if="categoryType === typeByLabel('Составной список')"
      v-model="category"
    />
  </div>
</template>

<script>
import SimpleValue from '@/components/views/account/handbook/detail/detailValue/SimpleValue'
import ComplexValue from '@/components/views/account/handbook/detail/detailValue/ComplexValue'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'

export default {
  name: 'DetailValue',
  components: { SimpleValue, ComplexValue },
  mixins: [serviceDataComputeds],
  props: {
    categoryType: Number,
    value: {
      default: {},
      type: Object
    }
  },
  computed: {
    category: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
