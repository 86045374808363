<template>
  <div>
    <BaseDetail
      :is-loading=" loading "
      :is-scroll=" false "
    >
      <template #sidebar>
        <BaseSideBar dark>
          <BaseTree
            v-model="selectedItem"
            @input="handleSelectedItem"
            show-actions
            :region-btn="true"
          />
        </BaseSideBar>
      </template>
      <template #header>
        <div v-show="category">
          <BaseHeader
            @save="handleSave"
            :save-btn="$can(null, 'editHandbook')"
            :delete-btn="false"
            :close-btn="false"
            :import-btn="!!category && category.type === 3"
            @import="handleImport"
          />
        </div>
      </template>
      <template
        #content
        v-if="category"
        class="d-flex"
      >
        <DetailValue
          v-model="category"
          :category-type="category.type"
        />
      </template>
      <template
        #content
        v-else
      >
        <v-card
          width="100%"
          height="100%"
          class="d-flex align-start justify-start pa-3"
        >
          <BaseAlert
            type="info"
            dense
            icon="mdi-arrow-bottom-left"
            text="Выберите категорию и справочник"
          />
        </v-card>
      </template>
    </BaseDetail>
    <BaseImportPanel
      v-if="category"
      :extra-data="{
        commandType: 'parsing',
        params: {
          categoryId: category.id,
          objectType: 'handbook'
        }
      }"
      :category-id="category.id"
      file-type="xlsx"
      :object-type="'handbook'"
      v-model="importDialog"
      @parse:completed="handleCompletedLoad"
    />
  </div>
</template>

<script>
import BaseDetail from '@/components/base/BaseDetail'
import loading from '@/components/mixins/loading'
import BaseTree from '@/components/base/BaseTree'
import BaseSideBar from '@/components/base/BaseSideBar'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import BaseAlert from '@/components/base/UI/BaseAlert'
import DetailValue from '@/components/views/account/handbook/detail/DetailValue'
import importMixin from '@/components/mixins/import'
import BaseImportPanel from '@/components/base/BaseImportPanel'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [loading, importMixin],
  name: 'Detail',
  components: {
    BaseImportPanel,
    DetailValue,
    BaseAlert,
    BaseHeader,
    BaseSideBar,
    BaseTree,
    BaseDetail
  },
  data () {
    return {
      selectedItem: null,
      isLoading: {
        handbook: false
      },
      category: null
    }
  },
  validations: {
    category: {
      values: {
        $each: {
          value: {
            required,
            isUnique (value) {
              return this.uniqueName(value)
            }
          }
        }
      }
    }
  },
  computed: {
    handbookLoading () {
      return this.$store.getters['handbook/isLoading']
    }
  },
  watch: {
    handbookLoading (val) {
      this.isLoading.handbook = val
      if (this.category) {
        this.handleSelectedItem(this.category.id)
      }
    }
  },
  methods: {
    async handleSave () {
      if (!this.$v.category.$anyError) {
        await this.$store.dispatch('handbook/updateValues', this.category)
        this.handleSelectedItem(this.category.id)
        this.$v.category.$reset()
      }
    },
    handleSelectedItem (data) {
      this.category = data ? this.$store.getters['handbook/getListItemById'](data) : null
    },
    uniqueName (value) {
      let result = true
      if (this.category.values.filter(item => item.value === value).length > 1) {
        result = false
      }
      return result
    },
    handleCompletedLoad () {
      return this.$store.dispatch('handbook/fetch')
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  }
}
</script>

<style scoped>

</style>
