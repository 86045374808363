import { cleanClone, deepEqual } from '@/components/utils/common'

export default {
  data () {
    return {
      category: cleanClone(this.value),
      tableOptions: {}
    }
  },
  computed: {
    indexOffset () {
      return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (!deepEqual(newVal, oldVal)) {
          this.category = cleanClone(newVal)
        }
      },
      deep: true
    }
  },
  methods: {
    handleDelete (index) {
      this.category.values.splice(this.indexOffset + index, 1)
    }
  }
}
